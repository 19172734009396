<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-users-alt display-6 text-primary"></i></div>
              <div><h4 class="fw-medium font-size-20 text-info mb-0">Create {{ title }}</h4>
              <small class="d-block font-size-13 text-muted fw-normal">Merchant Info &amp; Password</small></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="mt-1">
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                        <label>Username</label>
                        <input
                          v-model.trim="formData.username"
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          name="username"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.username.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.username.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.username.required"
                            >Username is required.</span
                          >
                          <span v-else-if="!$v.formData.username.validUsername"
                            >Username must contain only lowercase alphabets and numbers.</span
                          >
                        </div>
                      </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>Name</label>
                          <input
                            v-model="formData.name"
                            type="text"
                            class="form-control"
                            placeholder="Name"
                            name="name"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && $v.formData.name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.name.required"
                              >Name is required.</span
                            >
                          </div>
                        </div>
                        
                      </div>
                      
                      
                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label>Website Url</label>
                          <input
                            v-model="formData.websiteUrl"
                            type="text"
                            class="form-control"
                            placeholder="Website Url"
                            name="website"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.websiteUrl.$error,
                            }"
                          />
                          <div
                            v-if="submitted && $v.formData.websiteUrl.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.websiteUrl.required"
                              >Website url is required.</span
                            >
                            <span v-else-if="!$v.formData.websiteUrl.validWebsiteUrl"
                              >Please enter a valid website url.</span
                            >
                            
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label>Payment Url</label>
                          <input
                            v-model="formData.paymentUrl"
                            type="text"
                            class="form-control"
                            placeholder="Payment Url"
                            name="paymentUrl"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.paymentUrl.$error,
                            }"
                          />
                          <div
                            v-if="submitted && $v.formData.paymentUrl.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.paymentUrl.required"
                              >Payment url is required.</span
                            >
                            <span v-else-if="!$v.formData.paymentUrl.validWebsiteUrl"
                              >Please enter a valid payment url.</span
                            >
                            
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label>Return Url</label>
                          <input
                            v-model="formData.returnUrl"
                            type="text"
                            class="form-control"
                            placeholder="Return Url"
                            name="returnUrl"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.returnUrl.$error,
                            }"
                          />
                          <div
                            v-if="submitted && $v.formData.returnUrl.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.returnUrl.required"
                              >Return url is required.</span
                            >
                            <span v-else-if="!$v.formData.returnUrl.validWebsiteUrl"
                              >Please enter a valid return url.</span
                            >
                            
                          </div>
                        </div>
                      </div>
                    </div>
                 

                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>Password</label>
                          <div>
                            <input
                              v-model="formData.password"
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.password.$error,
                              }"
                              placeholder="Password"
                            />
                            <div
                              v-if="submitted && $v.formData.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.password.required"
                                >Password is required.</span
                              >
                              <span v-if="!$v.formData.password.minLength"
                                >Password must be at least 8 characters.</span
                              >

                              <span v-if="!$v.formData.password.alpha">
                                Password must contain at least one number and
                                both lower and uppercase letters and special
                                characters. (e.g.: MercHant357#@, Admin12#@,
                                etc.)</span
                              >
                            </div>
                            <div
                              id="eye"
                              class="user-select-none"
                              @click="switchVisibility()"
                            >
                              <i
                                :class="classicon"
                                id="eyetype"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>Confirm Password</label>
                          <input
                            v-model="formData.confirmPassword"
                            :type="passwordFieldType2"
                            name="confirmPassword"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.confirmPassword.$error,
                            }"
                            placeholder="Confirm Password"
                          />
                          <div
                            v-if="
                              submitted && $v.formData.confirmPassword.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.confirmPassword.required"
                              >Confirm password is required.</span
                            >
                            <span
                              v-else-if="
                                !$v.formData.confirmPassword.sameAsPassword
                              "
                              >Confirm password does not match the new
                              password.</span
                            >
                          </div>
                          <div
                            id="eye2"
                            class="user-select-none"
                            @click="switchVisibility2()"
                          >
                            <i
                              :class="classicon2"
                              id="eyetype2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <p class="text-muted font-size-13">
                        <i
                          class="
                            uil uil-exclamation-triangle
                            font-size-16
                            text-warning
                            me-2
                          "
                        ></i>
                        Password is Case Sensitive.
                      </p>
                    </div>
                    <div class="mb-3 d-flex">
                      <button type="button" @click="resetData" class="btn btn-light me-2" :disabled="loading || buttonLoading[1] ">
                      <i class="uil-redo me-1"></i> 
                      <span v-if="buttonLoading[1]"> Resetting..</span>
                      <span v-else>  Reset </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[1]" role="status" aria-hidden="true"></span>
                      </button>

                      <button type="button" @click="save" class="btn btn-info me-2" :disabled="loading || buttonLoading[0]">
                      <i class="uil uil-plus-circle me-1"></i>
                      <span v-if="buttonLoading[0]"> Creating...</span>
                      <span v-else>  Create </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[0]" role="status" aria-hidden="true"></span>
                      </button>
                      
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-users-alt display-6 text-primary"></i></div>
              <div><h4 class="fw-medium font-size-20 text-info mb-0"> Merchant List</h4><small class="d-block font-size-13 text-muted fw-normal">All Merchant List </small></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="card text-center">
                  <div class="card-body">
                    
                     <h5 class="font-size-18 mb-3 text-info">Hugo</h5> 
                     <p class="text-info mb-0"><i class="uil uil-user me-1 text-primary"></i> merchant <span class="mx-2">|</span> <i class="uil uil-globe me-1 text-primary"></i><a href="#" class="text-info">https://1xbet.com</a></p>
                     <p class="text-muted mb-3"></p>
                    
                    <div class="input-group mb-1">
                      <div class="input-group-prepend">
                        <span class="input-group-text border-light">
                          <i class="uil uil-qrcode-scan text-primary me-1"></i> Merchant Code</span>
                      </div>
                      <input type="text" class="form-control form-control text-muted border-light" readonly value="M00001">
                    </div>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text border-light"><i class="uil uil-key-skeleton-alt text-primary me-1"></i> API Key</span>
                      </div>
                      <input type="text" class="form-control border-light text-muted " readonly value="4f8d3a2e-1b2c-4e5f-8d9a-0b1c2d3e4f5g">
                    </div>
                    
                     <button type="button" class="btn btn-sm btn-outline-success text-truncate"><i class="uil uil-check-circle font-size-16 me-1"></i> Connected</button> 
                    </div> 
                     <div role="group" class="btn-group">
                      <button type="button" class="btn btn-outline-light py-3 text-truncate" ><i class="uil uil-check-circle me-1 text-primary"></i> Enable</button> 
                      <button type="button" class="btn btn-outline-light py-3 text-truncate"><i class="uil uil-minus-circle me-1 text-danger"></i> Disable
                      </button>
                    </div>
                  </div>
              </div>
              <div class="col-lg-4">
                <div class="card text-center">
                  <div class="card-body">
                    
                     <h5 class="font-size-18 mb-3 text-info">Michie</h5> 
                     <p class="text-info mb-0"><i class="uil uil-user me-1 text-primary"></i> michie <span class="mx-2">|</span> <i class="uil uil-globe me-1 text-primary"></i><a href="#" class="text-info">https://www.indiabet.com</a></p>
                     <p class="text-muted mb-3"></p>
                    
                    <div class="input-group mb-1">
                      <div class="input-group-prepend">
                        <span class="input-group-text border-light">
                          <i class="uil uil-qrcode-scan text-primary me-1"></i> Merchant Code</span>
                      </div>
                      <input type="text" class="form-control form-control text-muted border-light" readonly value="M00004">
                    </div>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text border-light"><i class="uil uil-key-skeleton-alt text-primary me-1"></i> API Key</span>
                      </div>
                      <input type="text" class="form-control border-light text-muted " readonly value="3f8d3a2e-2b2c-1e5f-2d9a-0b1c2d3e425g">
                    </div>
                    
                     <button type="button" class="btn btn-sm btn-outline-danger text-truncate"><i class="uil uil-minus-circle font-size-16 me-1"></i> Disconnected</button> 
                    </div> 
                     <div role="group" class="btn-group">
                      <button type="button" class="btn btn-outline-light py-3 text-truncate" ><i class="uil uil-check-circle me-1 text-primary"></i> Enable</button> 
                      <button type="button" class="btn btn-outline-light py-3 text-truncate"><i class="uil uil-minus-circle me-1 text-danger"></i> Disable
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
const validWebsiteUrl = (value) => {
  console.log(value);
  const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
  return regex.test(value);
}
const validUsername = (value) => {
  const regex = /^[a-z0-9]+$/;
  return regex.test(value);
};
/**
 * Merchant
 */
 const PageTitle = "Merchant"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "One68Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      username: "",
      merchant_code: "",
      merchant_key: "",
      formData: {
        username:"",
        name:"",
        apiKey: "",
        paymentUrl: "",
        returnUrl: "",
        websiteUrl: "",
        password: "",
        confirmPassword: "",
        otp: "",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
    };
  },
  validations: {
    formData: {
      username: {
        required,
        validUsername
      },
      name: {
        required,
      },
      apiKey: {
        required,
      },
      paymentUrl: {
        required,
        validWebsiteUrl
      },
      returnUrl: {
        required,
        validWebsiteUrl
      },
      websiteUrl: {
        required,
        validWebsiteUrl
      },
      password: {
        required,
        alpha,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    console.log( this.$refs.commonFunc.getPhone())
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    //this.getData();
    
  }, 
  created(){
   
  },
  methods:{
    validateInput() {
      // Remove any characters that are not lowercase alphabets or numbers
      this.inputValue = this.inputValue.toLowerCase().replace(/[^a-z0-9]/g, '');
    },
     resetData() {
      this.submitted = false;
      this.formData={
        username:"",
        name:"",
        paymentUrl: "",
        returnUrl: "",
        websiteUrl: "",
        password: "",
        confirmPassword: "",
        otp: "",
      }
    },
    switchVisibility_current() {
      (this.classicon_current =
        this.classicon_current === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType_current =
          this.passwordFieldType_current === "password" ? "text" : "password");
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
     switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
   getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.reload()
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    save() {
        this.submitted = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[0] = true
            //bodyFormData.append("accessToken", this.accessToken);
            const datas = {
              username: this.accessUsername,
              token: this.accessToken,
            };
            const sendRequest = async () => {
              try {
                const response = await 
                axios.post(appConfig.DemoAPI, 
                  JSON.stringify(datas), {
                   headers: { "Content-Type": "multipart/form-data" },
                  });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                      console.log(resData)
                      Swal.fire({
                        icon: 'success',
                        title: 'Merchant Created',
                        html: `Merchant have been successfully created!`,
                        confirmButtonColor: '#FA6541',
                        confirmButtonText: this.$t('siteLang.Done')
                      })
                    //   const updateData = {
                    //     "name": this.formData.name,
                    // }
                    // this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                    // this.formData.name = "";
                    this.resetData()
                    this.reload()
                  }
                  if (resData.status == 500) {
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  
                  this.loading = false;
                  this.submitted = false
                  this.buttonLoading[0] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[0] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                }
            };

            sendRequest();
        }
    },
    
    reset() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("phone",  this.accessPhone);
            axios({
                method: "post",
                url: appConfig.DemoAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    html: `API ID and API Hash have been successfully deleted and reset!`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                  const updateData = {
                    "apiID": 'YOUR_API_ID',
                    "apiHash": 'YOUR_API_HASH',
                 }
                 this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                 this.reload()
                   
                } 
                else if (resData.status == 401){
                  Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    this.$refs.commonFunc.clearData()
                    this.$router.push({
                        path: "/login",
                  });
                }
                else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                }
                this.loading = false;
                this.submitted = false
                this.buttonLoading[1] = false
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading[1] = false
                this.loading = false;
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.username = data.username;
          this.merchant_key = data.merchant_key;
          this.merchant_code = data.merchant_code;
          this.formData.name = data.name;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3{
  position: absolute;
  top: 32px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>